import React, { useRef, useCallback, useEffect, useState } from "react";
import { Link } from "gatsby";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
// import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useGesture } from "@use-gesture/react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import cx from "classnames";
import { useSiteContext } from "@lib/context";

const Slide = ({ title, excerpt, featuredImage, slug, activeSlide }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const boxVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: [0, 1, 1, 1, 1, 0],
      transition: {
        delay: 1,
        duration: 6.5,
      },
    },
  };

  return (
    <div
      className="grid grid-cols-14 h-screen items-center justify-center w-full relative mt-8 md:mt-0"
      style={{ flex: "auto" }}
    >
      <div className="col-start-2 col-end-14 lg:col-start-4 lg:col-end-12">
        <div
          className="relative aspect-w-16 aspect-h-12 md:aspect-w-16 md:aspect-h-10"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {featuredImage && (
            <Link to={`/${slug?.current}`}>
              {/* <SanityImage
                image={featuredImage}
                className="absolute top-0 left-0 w-full h-full object-cover"
              /> */}
              <img
                src={featuredImage?.asset?.url}
                alt=""
                loading="lazy"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </Link>
          )}
        </div>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={activeSlide}
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={boxVariants}
            // exit="outro"
            className="flex flex-col lg:flex-row gap-x-16 justify-between font-slim"
          >
            <span
              className={cx(
                "blockH5 block mt-4 w-full transition-all ease-in-out duration-300",
                {
                  "text-white": !hover,
                  "text-salmon": hover,
                }
              )}
            >
              {title}
            </span>
            <span className="blockH8 block lg:mt-6 w-full text-white">
              {excerpt}
            </span>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

const Carousel = ({ slides }) => {
  const breakpoints = useBreakpoint();
  const { state } = useSiteContext();
  const [activeSlide, setActiveSlide] = useState(0);

  const options = {
    axis: "y",
    skipSnaps: true,
    loop: true,
    speed: 5,
  };

  const autoplay = useRef(
    Autoplay(
      { delay: 8000, stopOnInteraction: false, playOnInit: false },
      (emblaRoot) => emblaRoot.parentElement
    )
  );

  useEffect(() => {
    setTimeout(
      () => {
        autoplay?.current?.play();
      },
      state ? 8000 : 1000
    );
  }, []);

  const [viewportRef, emblaApi] = useEmblaCarousel(options, [autoplay.current]);

  const scrollNext = useCallback(() => {
    if (!emblaApi) return;
    breakpoints.lg && setActiveSlide(emblaApi.selectedScrollSnap());
    emblaApi.scrollNext();
    autoplay.current.reset();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    breakpoints.lg && setActiveSlide(emblaApi.selectedScrollSnap());
    autoplay.current.reset();
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    breakpoints.lg && setActiveSlide(emblaApi.selectedScrollSnap());
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  //scroll events
  const bind = useGesture({
    onWheelStart: () => breakpoints.lg && scrollNext(),
    onDrag: () => breakpoints.lg && setActiveSlide(false),
  });

  const slideVars = {
    initial: {
      y: "100vh",
    },
    animate: {
      y: 0,
      transition: {
        duration: 1.5,
        delay: state ? 9 : 1.5,
      },
    },
  };

  return (
    <motion.div
      variants={slideVars}
      initial="initial"
      animate="animate"
      className="relative z-10 overflow-hidden h-screen"
    >
      <div ref={viewportRef} className="h-screen">
        <div className="h-screen" {...bind()}>
          {slides?.map((slide, i) => (
            <Slide key={i} {...slide} activeSlide={activeSlide} />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Carousel;

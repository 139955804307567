import React from "react";
import lottie from "lottie-web";

const LottiePlayer = ({
  url,
  options = {},
  playing = false,
  autoplay = true,
  ...props
}) => {
  const target = React.useRef(null);
  const animation = React.useRef(null);

  React.useEffect(() => {
    // Mount the animation
    if (!animation.current) {
      animation.current = lottie.loadAnimation({
        container: target.current,
        path: url,
        loop: true,
        autoplay,
        renderer: "svg",
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      });
    }
    if (playing && animation.current) {
      animation.current.play();
    }
    if (!playing && animation.current) {
      animation.current.pause();
    }
  }, [playing]);

  if (!url) return null;

  return <div ref={target} {...props} />;
};

export default LottiePlayer;

import React from "react";
import { motion } from "framer-motion";
import { useSiteContext } from "@lib/context";
import Carousel from "./carousel";

import Tagline from "@svg/tagline.svg";

const FixedText = () => {
  const { state } = useSiteContext();

  const fixedVars = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1,
        delay: state ? 8 : 0,
      },
    },
  };

  return (
    <motion.div
      variants={fixedVars}
      initial="initial"
      animate="animate"
      className="text-teal text-5xl md:text-7xl lg:text-9xl fixed h-full w-full flex flex-col items-center justify-center px-gutter"
    >
      {/* <span className="block">Small Team.</span>
      <span className="block">Big Ideas.</span> */}
      <Tagline className="w-full md:w-4/6" />
    </motion.div>
  );
};

const HomeSlider = ({ slides }) => {
  return (
    <div className="min-h-screen w-full bg-dark-green overflow-hidden">
      <FixedText />
      <Carousel slides={slides} />
    </div>
  );
};

export default HomeSlider;

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useSiteContext } from "@lib/context";
// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import Layout from "@components/layout";
import Animation from "@components/home/animation";
import HomeSlider from "@components/home/homeSlider";
import { PageMeta } from "@components/pageMeta";

const IndexPage = ({ data: { page } }) => {
  const { seo, lottie, text, slides } = page || {};

  const [reverseHeader, setReverseHeader] = useState(true);
  const { state, setState } = useSiteContext();

  const [isStart, setIsStart] = useState(true);

  useEffect(() => {
    setTimeout(
      () => {
        setIsStart(false);
      },
      state ? 1000 : 0
    );
  });

  useEffect(() => {
    setTimeout(() => {
      setState(false);
    }, 10000);
  }, []);

  return (
    <Layout
      hideFooter={true}
      homeNav={true}
      reverseHeader={reverseHeader}
      setReverseHeader={setReverseHeader}
    >
      <PageMeta {...seo} />
      {state && <Animation lottie={lottie} text={text} />}
      {isStart && (
        <div
          style={{ zIndex: "100" }}
          className="absolute top-0 left-0 w-full h-full bg-teal"
        />
      )}
      <HomeSlider slides={slides} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      lottie: curtainImage {
        asset {
          url
        }
      }
      text: _rawCurtainText(resolveReferences: { maxDepth: 10 })
      slides: homeSlider {
        title
        slug {
          current
        }
        featuredImage {
          asset {
            url
          }
        }
        excerpt
      }
    }
  }
`;

import React from "react";
import { motion } from "framer-motion";
import LottiePlayer from "@components/media/lottiePlayer";
import SimplePortableText from "@components/sanity/simplePortableText";

const curtainVars = {
  initial: {
    y: 0,
  },
  animate: {
    y: "-100vh",
    transition: {
      duration: 1,
      delay: 3,
    },
  },
};

const textVars = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      duration: 1,
      delay: 7,
    },
  },
};

const Intro = ({ text }) => {
  return (
    <motion.div
      variants={textVars}
      initial="initial"
      animate="animate"
      className="bg-salmon text-center absolute w-full h-full flex items-center justify-center px-gutter z-[40]"
    >
      <motion.div
        initial={{ filter: "blur(8px)" }}
        animate={{
          filter: "blur(0px)",
          transition: { delay: 3.25, transition: 1 },
        }}
      >
        <SimplePortableText
          text={text}
          className="max-w-[1000px] w-full transition-all ease-in-out duration-300 text-dark-green flex flex-col gap-y-6"
        />
      </motion.div>
    </motion.div>
  );
};

const Curtain = ({ lottie }) => {
  return (
    <motion.div
      variants={curtainVars}
      initial="initial"
      animate="animate"
      className="w-full h-full relative z-[1000]"
    >
      {/* <SanityImage image={img} className='absolute top-0 left-0 w-full h-full object-cover' /> */}

      {lottie?.asset && (
        <LottiePlayer
          url={lottie?.asset?.url}
          playing={true}
          autoplay={false}
          className="absolute top-0 left-0 w-full h-screen object-cover transform scale-100"
        />
      )}
    </motion.div>
  );
};

const Animation = ({ lottie, text }) => {
  return (
    <div className="h-screen w-screen absolute flex items-center justify-center pointer-events-none ">
      <Curtain lottie={lottie} />
      <Intro text={text} />
    </div>
  );
};

export default Animation;
